import { Config } from '../../interfaces/config.interface';
// import Img1 from './img1.png';
// import Img2 from './img2.png';
import Logo from './logo.jpg';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  name: 'Workertech',
  has_hours: false,
};

export default config;
